import React from "react";
import Layout from "../../Layout";
import "./style.scss";
import SEO from "../../seo";
export default function Registrations() {
  return (
    <Layout>
      <SEO
        title="Online Registrations | Chinmaya Vidyalaya Delhi | Chinmaya Mission"
        description="Chinmaya Vidyalaya vasant vihar Delhi offer Online registrations open for Nursery to class 9th for session 2021-22. Apply now for online vidyalaya registrations"
        img="https://www.chinmayavvdelhi.ac.in/images/Blackele/Chinmaya_link_preview.jpg"
        keywords="chinmaya mission, chinmaya vidyalaya delhi, online vidyalaya, online registrations nursery,chinmaya vidyalaya vasant vihar"
      />
      <div className="registrations">
        <h1 className="heading">Registrations</h1>

        <div className="row">
          <div className="col col-12 col-lg-6">
            <div className="registrations__card">
              <h2>Online Nursery Registration</h2>
              <div className="registrations__card__date">
                1st December 2022 Onwards
              </div>
              <a
                target="_blank"
                href="https://cvvvar.iwsportal.com:572/"
                className="btn-dark"
              >
                Click Here For Registration
              </a>
            </div>
          </div>
          <div className="col col-12 col-lg-6">
            <div className="registrations__card">
              <h2>Online Registration for KG – IX</h2>
              <div className="registrations__card__date">
                1st December 2022 Onwards
              </div>
              <a
                href="https://cvvvarother.iwsportal.com:572/"
                target="_blank"
                className="btn-dark"
              >
                Click Here For Registration
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="tel">
        FOR ADMISSION ENQUIRY CALL -{" "}
        <a href="tel:9999834424">
          <strong>9999834424</strong>
        </a>
      </div>
    </Layout>
  );
}
